import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Col, Container, Row, Button } from "react-bootstrap";
import Context from "../Context";

const Recipedisplay = ({ id }) => {
  const navigate = useNavigate();

  const [state, dispatch] = useContext(Context);

  function getRecipe() {
    let loadedRecipe = state.recipeList.filter((rec) => { 
      return rec.id == id})[0];
    return loadedRecipe;
  }
    

  const deleteRecipe = () => {
    // let recipeListCopy = state.recipeList;
    // let updatedList = recipeListCopy.filter((i) => i.id !== recipe.id)
    // dispatch({ type: "updateRecipeList", payload: updatedList });
    // navigate(`/myrecipes`);

    const options = {
      method: 'DELETE'
    }

    fetch(`${state.apiUrl}/api/recipes/${id}`, options)
      .then(response => {
        console.log(response.status)
      })
      .then(() => {
        goBack();
      })
      

  };

  function goBack() {
    window.history.back();
  }

  const recipe = getRecipe();

  return (
    <>
      {recipe !== undefined ?
        <div className="recipeBox">
          <h2>{recipe.name}</h2>
          <h4>Ingredients</h4>
          <ul>
            {recipe.ingredients.map((ing) => (
              <li className="ingredient-listgroup-item" key={uuidv4()}>
                {ing.amount} {ing.fraction} {ing.unit} - {ing.name}
              </li>
            ))}
          </ul>
          <h4>Steps</h4>
          <ol>
            {recipe.directions.map((step) => (
              <li key={uuidv4()}> {step}</li>
            ))}
          </ol>
          <div id="isGlutenDisplay">
            {recipe.glutenFree ? `✔` : `❌`} Gluten Free
          </div>
          <div id="recipeDisplayButtons">
            {/* <Button onClick={deleteRecipe}>Delete</Button> */}
            {/* <Link to={`/myrecipes/edit/${recipe.id}`}>
              <Button>Edit</Button>
            </Link> */}
            <Button onClick={goBack}>Go Back</Button>
          </div>
        </div>
        : "Recipe Loading"}
    </>
  );
};

export default Recipedisplay;
