import React, { useEffect, useState, useContext } from "react";
import { Form, Container, Row, Col, ListGroup, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from "uuid";
import Context from "../Context";

const ingredientDetail = {
    name: "",
    amount: "",
    fraction: "",
    unit: "",
};

const Mypantry = () => {

    const [state, dispatch] = useContext(Context);
    const [ingredient, setIngredient] = useState(ingredientDetail);
    const [pantry, setPantry] = useState([]);

    const fractions = ["1/16", "1/8", "1/4", "1/3", "1/2", "2/3", "3/4", "7/8"];

    const units = ["cups", "tbls", "tsp", "cans", "lbs", "oz"];

    const ingredientChange = (e) => {
        setIngredient({ ...ingredient, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        dispatch({type: 'updatePantry', payload: {pantry}})
        console.log(state.pantry)
    }, [pantry]);

    const addIngredient = (e) => {
        e.preventDefault();
        if (ingredient.fraction === "") {
            let newIng = {
                id: uuidv4(),
                name: ingredient.name,
                amount: ingredient.amount,
                unit: ingredient.unit,
            };
            let newIngList = pantry.concat(newIng);
            setPantry(newIngList);
            setIngredient(ingredientDetail);
        } else {
            let newIng = {
                id: uuidv4(),
                name: ingredient.name,
                amount: `${ingredient.amount} ${ingredient.fraction}`,
                unit: ingredient.unit,
            };
            let newFraIngList = pantry.concat(newIng);
            setPantry(newFraIngList);
            setIngredient(ingredientDetail);
        }
    };

    const increaseCount = (itemId) => {
        let pantryCopy = [...pantry];
        pantryCopy.forEach((item) => {
            if(item.id === itemId){
                item.amount = parseInt(item.amount) + 1;
            }
        } )
        setPantry(pantryCopy);
    }

    const decreaseCount = (itemId) => {
        let pantryCopy = [...pantry];
        let itemIndex = -1;
        pantryCopy.forEach((item, index) => {
            if(item.id === itemId){
                item.amount = parseInt(item.amount) - 1;
            }
            if(item.amount === 0){
                itemIndex = index;
            }
        } )
        if(itemIndex >= 0){
            pantryCopy.splice(itemIndex, 1)
        }
        setPantry(pantryCopy);
    }


    return (
        <>
            {/* <h3>Add Pantry Item</h3> */}
            <Container>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Item:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Example: Baked Beans"
                                value={ingredient.name}
                                onChange={ingredientChange}
                                name="name"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Amount:</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Whole number"
                                value={ingredient.amount}
                                onChange={ingredientChange}
                                name="amount"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Fraction:</Form.Label>
                            <Form.Select
                                value={ingredient.fraction}
                                onChange={ingredientChange}
                                name="fraction"
                            >
                                <option></option>
                                {fractions.map((type, index) => (
                                    <option key={uuidv4()} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Unit:</Form.Label>
                            <Form.Select
                                value={ingredient.unit}
                                onChange={ingredientChange}
                                name="unit"
                            >
                                <option></option>
                                {units.map((type, index) => (
                                    <option key={uuidv4()} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Button
                    variant="primary"
                    id="addToPantryButton"
                    onClick={addIngredient}
                >
                    Add To Inventory
                </Button>
                <h4>My Pantry:</h4>
                <ListGroup id="ingredientList">
                    {pantry.map((ing) => (
                        <ListGroup.Item
                            className="ingredient-listgroup-item"
                            key={uuidv4()}
                        >
                            <div id='pantry-ingredient-list'>
                                <div id="pantry-item">{ing.amount} {ing.unit} - {ing.name}</div>
                                <div id="plus-minus-button-group">
                                    <Button id="plusButton" onClick={() => increaseCount(ing.id)}>+</Button>
                                    <Button id="minusButton" onClick={() => decreaseCount(ing.id)}>-</Button>
                                </div>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Container>
        </>
    );
}

export default Mypantry;
