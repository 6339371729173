import React from "react";
import Addrecipeform from "./AddRecipeForm";

const Addrecipe = () => {
  return (
    <>
      <section className="addFormSection">
        <Addrecipeform />
      </section>
    </>
  );
};

export default Addrecipe;
