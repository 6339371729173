import React, { useContext, useState, useRef } from "react";
import Context from "../Context";
import { v4 as uuidv4 } from "uuid";
import { Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import "../css/Planning.css";
import { useNavigate } from "react-router-dom";
import ReactStickyBox from "react-sticky-box";
import { useReactToPrint } from "react-to-print";

const initialAdvancedListState = [];

const initialPlan = {
  monday: {},
  tuesday: {},
  wednesday: {},
  thursday: {},
  friday: {},
  saturday: {},
  sunday: {},
};

export default function Planning() {
  const [advancedList, setAdvancedList] = useState(initialAdvancedListState);
  const [plan, setPlan] = useState(initialPlan);

  const [state, dispatch] = useContext(Context);

  const addToAdvancedList = (e) => {
    if (Object.keys(plan[e.target.name]).length === 0) {
      let selectedRecipe = getRecipe(e.target.value);
      addToPlan(e.target.name, selectedRecipe);
      updateAdvancedList(selectedRecipe);
    }
  };

  const updateAdvancedList = (recipe) => {
    let copyAdvancedList = [...advancedList];
    let toAdd = [];
    let toEdit = [];
    let copyIngredientList = [...recipe.recipeIngredients];
    copyIngredientList.forEach((recipeIngredient) => {
      let recipeIngredientName = recipeIngredient.name.split(",")[0];
      let didFind = advancedList.find(
        (item) => item.name.toLowerCase() === recipeIngredientName.toLowerCase()
      );
      if (didFind !== undefined) {
        let itemCopy = { ...didFind };
        let newUsedBy = `${recipeIngredient.amount} ${recipeIngredient.unit} - ${recipe.name}`;
        itemCopy.usedBy.push(newUsedBy);
        toEdit.push(itemCopy);
      } else {
        let newItem = {
          name: recipeIngredientName.toLowerCase(),
          usedBy: [
            `${recipeIngredient.amount} ${recipeIngredient.unit} - ${recipe.name}`,
          ],
        };
        toAdd.push(newItem);
      }
    });
    let newList = copyAdvancedList.concat(toAdd);
    setAdvancedList(newList);
  };

  const getRecipe = (id) => {
    return state.recipeList.find((recipe) => recipe.id === id);
  };

  const addToPlan = (day, recipe) => {
    let copyPlan = { ...plan };
    copyPlan[day] = recipe;
    setPlan(copyPlan);
  };

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const addToMyShoppingList = () => {
    let toAddToShoppingList = [...state.shoppingList];
    advancedList.forEach((item) => {
      toAddToShoppingList.push(item.name);
    });
    dispatch({ type: "addToShoppingList", payload: toAddToShoppingList });
    resetPlan();
    navigate("/mylist");
  };

  const navigate = useNavigate();

  function resetPlan() {
    let newList = [];
    setAdvancedList(newList);
    setPlan(initialPlan);
  }
  const shoppingListComponentRef = useRef();
  const printList = useReactToPrint({
      content: () => shoppingListComponentRef.current,

  });

  const mealListComponentRef = useRef();
  const printMealList = useReactToPrint({
      content: () => mealListComponentRef.current,

  });

  

  return (
    <Container id="planningRecipeDisplay">
      <Row id="planningRow">
        <Col md={4}>
          <ReactStickyBox>
            <Form ref={mealListComponentRef}>
              <h4>Monday</h4>
              <Form.Select
                name="monday"
                value={plan.monday.id}
                onChange={addToAdvancedList}
              >
                <option></option>
                {state.recipeList.map((recipe, index) => (
                  <option key={uuidv4()} value={recipe.id}>
                    {recipe.name}
                  </option>
                ))}
              </Form.Select>
              <h4>Tuesday</h4>
              <Form.Select
                name="tuesday"
                value={plan.tuesday.id}
                onChange={addToAdvancedList}
              >
                <option></option>
                {state.recipeList.map((recipe, index) => (
                  <option key={uuidv4()} value={recipe.id}>
                    {recipe.name}
                  </option>
                ))}
              </Form.Select>
              <h4>Wednesday</h4>
              <Form.Select
                name="wednesday"
                value={plan.wednesday.id}
                onChange={addToAdvancedList}
              >
                <option></option>
                {state.recipeList.map((recipe, index) => (
                  <option key={uuidv4()} value={recipe.id}>
                    {recipe.name}
                  </option>
                ))}
              </Form.Select>
              <h4>Thursday</h4>
              <Form.Select
                name="thursday"
                value={plan.thursday.id}
                onChange={addToAdvancedList}
              >
                <option></option>
                {state.recipeList.map((recipe, index) => (
                  <option key={uuidv4()} value={recipe.id}>
                    {recipe.name}
                  </option>
                ))}
              </Form.Select>
              <h4>Friday</h4>
              <Form.Select
                name="friday"
                value={plan.friday.id}
                onChange={addToAdvancedList}
              >
                <option></option>
                {state.recipeList.map((recipe, index) => (
                  <option key={uuidv4()} value={recipe.id}>
                    {recipe.name}
                  </option>
                ))}
              </Form.Select>
              <h4>Saturday</h4>
              <Form.Select
                name="saturday"
                value={plan.saturday.id}
                onChange={addToAdvancedList}
              >
                <option></option>
                {state.recipeList.map((recipe, index) => (
                  <option key={uuidv4()} value={recipe.id}>
                    {recipe.name}
                  </option>
                ))}
              </Form.Select>
              <h4>Sunday</h4>
              <Form.Select
                name="sunday"
                value={plan.sunday.id}
                onChange={addToAdvancedList}
              >
                <option></option>
                {state.recipeList.map((recipe, index) => (
                  <option key={uuidv4()} value={recipe.id}>
                    {recipe.name}
                  </option>
                ))}
              </Form.Select>
              
            </Form>

            <Button
                type="reset"
                id="planningResetPlanButton"
                onClick={resetPlan}
              >
                Reset Plan
              </Button>
              <Button id="planningResetPlanButton" onClick={printMealList}>Print Meal List</Button>
          </ReactStickyBox>
        </Col>
        <Col md={6} id="planningPageShoppingList">
          <h2>Shopping List:</h2>
          <ListGroup ref={shoppingListComponentRef}>
            {advancedList.map((item) => (
              <ListGroup.Item key={uuidv4()} variant="secondary">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{item.name}</div>
                  {item.usedBy.map((usedby) => (
                    <div key={uuidv4()}>
                      {usedby} <br></br>
                    </div>
                  ))}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>

          {advancedList.length === 0 ? (
            ""
          ) : (
            <>
              <Button onClick={addToMyShoppingList}>
                Add to My Shopping List
              </Button>
              <Button id="printListButton" onClick={printList}>Print Shopping List</Button>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
