import React, { useContext, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Context from "../Context";
import Recipecard from "./RecipeCard";
import "../css/MyRecipes.css";
import {
  Accordion,
  Col,
  Container,
  Form,
  Row,
  Dropdown,
} from "react-bootstrap";
import RecipeFilter from "./RecipeFilter";
import RecipeSearchBar from "./RecipeSearchBar";
import ReactStickyBox from "react-sticky-box";

const initialState = {
  filter: false,
  method: "",
  base: "",
  duration: "",
  list: [],
};

const Myrecipes = () => {
  const { search } = window.location;
  const query = new URLSearchParams(search).get("s");

  const [state, dispatch] = useContext(Context);
  const [filter, setFilter] = useState(initialState);
  const [searchQuery, setSearchQuery] = useState(query || "");
  const [allRecipes, setAllRecipes] = useState([]);
  const [filteredResults, setFilteredResults] = useState(allRecipes)

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    updateFilteredList();
  };

  const updateFilteredList = (searchQuery) => {
    let filteredArray = [...state.recipeList];

    if (searchQuery) {
      filteredArray = filteredArray.filter((recipe) => {
        console.log("recipeName: ", recipe.name.toLowerCase())
        const recipeName = recipe.name.toLowerCase();
        return recipeName.includes(searchQuery.toLowerCase());
      });
    }

    if (filter.method !== "") {
      filteredArray = filteredArray.filter((recipe) => {
        return recipe.recipeMethod === filter.method;
      });
    }
    if (filter.base !== "") {
      filteredArray = filteredArray.filter((recipe) => {
        return recipe.recipeBase === filter.base;
      });
    }
    if (filter.duration !== "") {
      filteredArray = filteredArray.filter((recipe) => {
        return recipe.recipeDuration === filter.duration;
      });
    }
    return filteredArray;
  };

  const filteredItems = updateFilteredList(searchQuery);

  return (
    <>
    {console.log("query: ", query)}
      <Row id="myRecipesListRow">
        <Col xs={3} id="recipeSearchBarContainer">
          <ReactStickyBox>
            {console.log("filter: ", filter)}
            {console.log("Filtered List: ", filteredItems)}
            <RecipeSearchBar
              id="recipeSearchBar"
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Other Filters</Accordion.Header>
                <Accordion.Body>
                  <RecipeFilter
                    onChange={onChange}
                    filter={filter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    seederData={state.seederData}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </ReactStickyBox>
        </Col>
        <Col xs={9}>
          <h1 id="recipeListHeader">Recipes</h1>
          <section className="myRecipesDisplayArea">
            {filteredItems.length > 0
              ? filteredItems.map((recipe, index) => (
                  // <Link to={`/myrecipes/${recipe.id}`}>
                  <Recipecard recipe={recipe} index={index} key={uuidv4()} />
                  // </Link>
                ))
              : "No Items"}
          </section>
        </Col>
      </Row>
    </>
  );
};

export default Myrecipes;
