import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { Col, Container, Row, Button } from 'react-bootstrap';
import Recipedisplay from './RecipeDisplay';
import { v4 as uuidv4 } from 'uuid';
import Context from '../Context';




const IndividualRecipeDisplay = () => {
    const {id} = useParams();

    const [state, dispatch] = useContext(Context)

    
    return (
        <>
        {console.log("recipeList: ", state.recipeList)}
            {state.recipeList.length > 0 ? <Recipedisplay id={id} /> : "Recipe Page Loading"}
            
        </>
    );
}

export default IndividualRecipeDisplay;
