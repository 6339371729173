import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import {Link} from 'react-router-dom'

const Recipecard = ({ recipe, index }) => {
  return (
    <Card className='recipeCard'>
      <Card.Body>
        <Link to={`/myrecipes/${recipe.id}`}><Card.Title className='recipeCardTitle'>{recipe.name}</Card.Title></Link>
        <ListGroup variant="flush">
          <ListGroup.Item>Method: {recipe.recipeMethod}</ListGroup.Item>
          <ListGroup.Item>Base: {recipe.recipeBase}</ListGroup.Item>
          <ListGroup.Item>Duration: {recipe.recipeDuration}</ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default Recipecard;
