import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";


const ImportRecipe = () => {
  const [recipeUrl, setrecipeUrl] = useState("");

  function setRecipeUrl(e) {
    let url = e.target.value;
    setRecipeUrl(url);
  }

  
  return (
    <div>
      <Form className="add-recipe-by-url">
        <Form.Group
          className="mb-3"
          controlId="formBasicEmail"
          value={recipeUrl}
          onChange={setRecipeUrl}
        >
          <Form.Label>Recipe URL</Form.Label>
          <Form.Control
            type="text"
            placeholder="Example: https://www.101cookbooks.com/everything-bagel-breakfast-casserole/"
          />
        </Form.Group>
        <Button variant="primary" >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ImportRecipe;
