import React, { useEffect, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col, Container, Row, ListGroup, Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import Context from "../Context";

const initialRecipe = {
  // id: uuidv4(), // id of recipe -- AUTO
  name: "", //the name of the recipe -- USER
  recipeMethod: -1, //Crockpot, grilled, oven, stovetop  --USER
  recipeBase: -1, // chicken, beef, pasta, other--USER
  recipeDuration: "", // from start to finish, how long does it take to cook  --USER
  directions: [], // step by stem instructions to make meal - list of strings -- USER
  ingredients: [],
  glutenFree: false,
};

const ingredientDetail = {
  name: "",
  amount: "",
  fraction: "",
  unit: "",
};

const Addrecipeform = () => {
  const [state, dispatch] = useContext(Context);
  const [recipe, setRecipe] = useState(initialRecipe);
  const [step, setStep] = useState("");
  const [ingredient, setIngredient] = useState(ingredientDetail);
  const [editIngredient, setEditIngredient] = useState(ingredientDetail);
  const [editShow, setEditShow] = useState([false, 0]);

  const [editStep, setEditStep] = useState("");
  const [editStepShow, setEditStepShow] = useState([false, 0]);

  //load seeder data
  useEffect(() => {
    fetch(`${state.apiUrl}/api/seeder`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let seederData = data;
        dispatch({ type: "updateSeederData", payload: seederData });
      });
  }, []);

  const onChange = (e) => {
    setRecipe({ ...recipe, [e.target.name]: e.target.value });
  };

  const stepChange = (e) => {
    setStep(e.target.value);
  };

  const editStepChange = (e) => {
    setEditStep(e.target.value);
  };

  const addStep = (e) => {
    e.preventDefault();
    let newStep = [step];
    let newRecipe = recipe.directions.concat(newStep);
    setRecipe({ ...recipe, directions: newRecipe });
    setStep("");
  };

  const ingredientChange = (e) => {
    setIngredient({ ...ingredient, [e.target.name]: e.target.value });
  };

  const editedIngredientChange = (e) => {
    setEditIngredient({ ...editIngredient, [e.target.name]: e.target.value });
  };

  const editedSTepChange = (e) => {
    setEditStep(e.target.value);
  };

  const addIngredient = (e) => {
    e.preventDefault();
    let newFraIngList = [...recipe.ingredients];
    newFraIngList.push(ingredient);
    setRecipe({ ...recipe, ingredients: newFraIngList });
    setIngredient(ingredientDetail);
  };

  const saveEditedIngredient = (e) => {
    e.preventDefault();
    let newFraIngList = [...recipe.ingredients];
    newFraIngList[editShow[1]] = editIngredient;
    setRecipe({ ...recipe, ingredients: newFraIngList });
    setIngredient(ingredientDetail);
    editHide();
  };

  const saveEditedStep = (e) => {
    // e.preventDefault();
    let newFraIngList = [...recipe.directions];
    newFraIngList[editStepShow[1]] = editStep;
    setRecipe({ ...recipe, directions: newFraIngList });
    setStep("");
    editStepHide();
  };

  const glutenCheck = () => {
    setRecipe({ ...recipe, glutenFree: !recipe.glutenFree });
  };

  const addRecipe = () => {
    addRecipeToDB(recipe);

    getAllRecipes();

    let recipeListCopy = state.recipeList;
    recipeListCopy.push(recipe);
    dispatch({ type: "updateRecipeList", payload: recipeListCopy });
    console.log(recipe);
    setRecipe(initialRecipe);
  };

  const addRecipeToDB = (newRecipe) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRecipe),
    };

    fetch(`${state.apiUrl}/api/recipes`, options)
      .then((response) => {
        return JSON.stringify(response);
      })
      .then((data) => {
        console.log(data);
      });
  };

  const getAllRecipes = () => {
    fetch(`${state.apiUrl}/api/recipes`)
      .then((response) => {
        return JSON.stringify(response);
      })
      .then((data) => {
        console.log(data);
      });
  };

  function editDirection(index) {
    let editStepShowCopy = [...editStepShow];
    editStepShowCopy[0] = true;
    editStepShowCopy[1] = index;
    setEditStepShow(editStepShowCopy);
    setEditStep(recipe.directions[index]);
  }

  function editSelectedIngredient(index) {
    let editShowCopy = [...editShow];
    editShowCopy[0] = true;
    editShowCopy[1] = index;
    setEditShow(editShowCopy);
    setEditIngredient(recipe.ingredients[index]);
  }

  function editHide() {
    let editShowCopy = [...editShow];
    editShowCopy[0] = false;
    editShowCopy[1] = 0;
    setEditShow(editShowCopy);
  }

  function editStepHide() {
    let editStepShowCopy = [...editShow];
    editStepShowCopy[0] = false;
    editStepShowCopy[1] = 0;
    setEditStepShow(editStepShowCopy);
  }

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="formRecipeName">
          <Form.Label>Recipe Name</Form.Label>
          <Form.Control
            type="text"
            value={recipe.name}
            onChange={onChange}
            placeholder="Example: Million Dollar Spaghetti"
            name="name"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRecipeCookingMethod">
          <Form.Label>Recipe Cooking Method:</Form.Label>
          <Form.Select
            value={recipe.recipeMethod}
            onChange={onChange}
            name="recipeMethod"
          >
            <option></option>
            {state.seederData.recipeMethods !== undefined
              ? state.seederData.recipeMethods.map((type, index) => (
                  <option key={uuidv4()} value={type.name}>
                    {type.name}
                  </option>
                ))
              : "Options Loading"}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRecipeBase">
          <Form.Label>Main Recipe Base:</Form.Label>
          <Form.Select
            value={recipe.recipeBase}
            onChange={onChange}
            name="recipeBase"
          >
            <option></option>
            {state.seederData.recipeBases !== undefined
              ? state.seederData.recipeBases.map((type, index) => (
                  <option key={uuidv4()} value={type.name}>
                    {type.name}
                  </option>
                ))
              : "Options Loading"}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRecipeDuration">
          <Form.Label>Recipe Duration:</Form.Label>
          <Form.Select
            value={recipe.recipeDuration}
            onChange={onChange}
            name="recipeDuration"
          >
            <option></option>
            {state.seederData.recipeDurations !== undefined
              ? state.seederData.recipeDurations.map((type, index) => (
                  <option key={uuidv4()} value={type.name}>
                    {type.name}
                  </option>
                ))
              : "Options Loading"}
          </Form.Select>
          <Form.Text className="text-muted">
            From start to finish, how long will it take to make this recipe?
          </Form.Text>
        </Form.Group>
        {/* <Form> */}
        <Form.Group className="mb-3" controlId="formAddStep">
          <Form.Label>Recipe Step:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Example: Mix all dry ingredients"
            value={step}
            onChange={stepChange}
          />
          <Button variant="primary" id="addStepButton" onClick={addStep}>
            Add Step
          </Button>
          <h4>Steps:</h4>
          <ListGroup id="stepsList">
            {recipe.directions.map((step, index) => (
              <>
                <ListGroup.Item
                  variant="success"
                  className="steps-listgroup-item"
                  key={uuidv4()}
                >
                  Step {index + 1} - {step}
                  <Button
                    onClick={() => {
                      editDirection(index);
                    }}
                  >
                    Edit Step
                  </Button>
                </ListGroup.Item>
              </>
            ))}
          </ListGroup>
        </Form.Group>
        {/* </Form> */}

        <h3>Ingredients</h3>
        {/* <Form> */}
        <Container>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ingredient:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Example: Baked Beans"
                  value={ingredient.name}
                  onChange={ingredientChange}
                  name="name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Amount:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Whole number"
                  value={ingredient.amount}
                  onChange={ingredientChange}
                  name="amount"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Fraction:</Form.Label>
                <Form.Select
                  value={ingredient.fraction}
                  onChange={ingredientChange}
                  name="fraction"
                >
                  <option></option>
                  {state.seederData.fractions !== undefined
                    ? state.seederData.fractions.map((type, index) => (
                        <option key={uuidv4()} value={type.name}>
                          {type.name}
                        </option>
                      ))
                    : "Options Loading"}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Unit:</Form.Label>
                <Form.Select
                  value={ingredient.unit}
                  onChange={ingredientChange}
                  name="unit"
                >
                  <option></option>
                  {state.seederData.units !== undefined
                    ? state.seederData.units.map((type, index) => (
                        <option key={uuidv4()} value={type.name}>
                          {type.name}
                        </option>
                      ))
                    : "Options Loading"}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="primary"
            id="addIngredientButton"
            onClick={addIngredient}
          >
            Add Incredient
          </Button>
          <h4>Ingredients:</h4>
          <ListGroup id="ingredientList">
            {recipe.ingredients.map((ing, index) => (
              <ListGroup.Item
                className="ingredient-listgroup-item"
                key={uuidv4()}
              >
                {ing.amount} {ing.fraction} {ing.unit} - {ing.name}
                <Button
                  onClick={() => {
                    editSelectedIngredient(index);
                  }}
                >
                  Edit Ingredient
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>

        {/* </Form> */}

        <Form.Group className="mb-3" controlId="isGluetenFree">
          <Form.Check
            type="checkbox"
            label="Glueten Free?"
            onChange={glutenCheck}
          />
        </Form.Group>
        <Button variant="primary" onClick={addRecipe}>
          Add Recipe
        </Button>
      </Form>
      {/* Edit modal for indivitual step */}
      <Modal show={editStepShow[0]} onHide={editStepHide}>
        <Form.Group className="mb-3" controlId="formAddStep">
          <Form.Label>Recipe Step:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Example: Mix all dry ingredients"
            value={editStep}
            onChange={editStepChange}
          />

          <Button
            onClick={() => {
              saveEditedStep();
            }}
          >
            Save Step
          </Button>
        </Form.Group>
      </Modal>

      {/* Modal for editing ingredients */}
      <Modal show={editShow[0]} onHide={editHide}>
        <Container>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ingredient:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Example: Baked Beans"
                  value={editIngredient.name}
                  onChange={editedIngredientChange}
                  name="name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Amount:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Whole number"
                  value={editIngredient.amount}
                  onChange={editedIngredientChange}
                  name="amount"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Fraction:</Form.Label>
                <Form.Select
                  value={editIngredient.fraction}
                  onChange={editedIngredientChange}
                  name="fraction"
                >
                  <option></option>
                  {state.seederData.fractions !== undefined
                    ? state.seederData.fractions.map((type, index) => (
                        <option key={uuidv4()} value={type.name}>
                          {type.name}
                        </option>
                      ))
                    : "Options Loading"}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Unit:</Form.Label>
                <Form.Select
                  value={editIngredient.unit}
                  onChange={editedIngredientChange}
                  name="unit"
                >
                  <option></option>
                  {state.seederData.units !== undefined
                    ? state.seederData.units.map((type, index) => (
                        <option key={uuidv4()} value={type.name}>
                          {type.name}
                        </option>
                      ))
                    : "Options Loading"}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="primary"
            id="addIngredientButton"
            onClick={saveEditedIngredient}
          >
            Save Ingredient
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default Addrecipeform;
