import React, { useContext, useEffect } from 'react';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Context from '../Context';
import planningImage from '../Assests/marissa-grootes-N9uOrBICcjY-unsplash small.jpg'
import pantryImage from '../Assests/annie-spratt-WxiblgUeQpM-unsplash.jpg'
import shoppingList from '../Assests/glenn-carstens-peters-RLw-UC03Gwc-unsplash.jpg'
import recipeBook from '../Assests/frank-holleman-rN_RMqSXRKw-unsplash.jpg'




const Home = () => {

    const [state, dispatch] = useContext(Context)

    // useEffect(() => {
    //     console.log("state: ", state)
    //     console.log('recipelist ', state.recipeList)
    // }, [state]);

    return (
        <main className='mainHomePage'>
            <h1 className='welcomeHome'>Welcome Home!</h1>
            <Container fluid="true">
                <Row >
                    <Col className='home-card-col'>
                        <Card className="home-cards text-center">
                            <Card.Img variant="top" src={planningImage} />
                            <Card.Body>
                                <Card.Title>Meal Planning</Card.Title>
                                <Card.Text>
                                    Plan weekly meal schedules and generate a time saving shopping list
                                </Card.Text>
                                <Link to='/planning'><Button variant="primary">Meal Planning</Button></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='home-card-col'>
                        <Card className="home-cards text-center">
                            <Card.Img variant="top" src={pantryImage} />
                            <Card.Body>
                                <Card.Title>My Pantry</Card.Title>
                                <Card.Text>
                                    Keep track of your pantry and automatically create meal schedules
                                </Card.Text>
                                <Link to='/mypantry'><Button variant="primary">Meal Planning</Button></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className='home-card-col'>
                        <Card className="home-cards text-center">
                            <Card.Img variant="top" src={shoppingList} />
                            <Card.Body>
                                <Card.Title>My Shopping List</Card.Title>
                                <Card.Text>
                                    Add and remove items from your shopping list
                                </Card.Text>
                                <Link to='/planning'><Button variant="primary">Meal Planning</Button></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='home-card-col'>
                        <Card className="home-cards text-center">
                            <Card.Img variant="top" src={recipeBook} />
                            <Card.Body>
                                <Card.Title>My Recipes</Card.Title>
                                <Card.Text>
                                    View, edit, and delete recipes from your cookbook.
                                </Card.Text>
                                <Link to='/planning'><Button variant="primary">Meal Planning</Button></Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </main>
    );
}

export default Home;
