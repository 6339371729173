import { Routes, Route } from 'react-router-dom';
import App from './App';
import Home from './Components/Home';
import Mypantry from './Components/MyPantry';
import Myrecipes from './Components/MyRecipes';
import Addrecipe from './Components/AddRecipe'
import Planning from './Components/Planning';
import IndividualRecipeDisplay from './Components/IndividualRecipeDisplay';
import EditRecipe from './Components/EditRecipe';
import Mylist from './Components/MyList';
import ImportRecipe from './Components/ImportRecipe';


export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Home />}></Route>
                <Route path="/mylist" element={<Mylist />}></Route>
                <Route path='/myrecipes' element={<Myrecipes />}></Route>
                <Route path='/mypantry' element={<Mypantry />}></Route>
                <Route path='/addrecipe' element={<Addrecipe />}></Route>
                <Route path='/planning' element={<Planning />}></Route>
                <Route path='/myrecipes/:id' element={<IndividualRecipeDisplay />}></Route>
                <Route path='/myrecipes/edit/:id' element={<EditRecipe />} ></Route>
                <Route path='/importrecipe' element={<ImportRecipe />}></Route>
            </Route>
        </Routes>
    )
}