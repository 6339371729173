import React, { useEffect, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col, Container, Row, ListGroup } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import Context from "../Context";
import { useParams, useNavigate } from "react-router-dom";

// const initialRecipe = {
//   id: uuidv4(), // id of recipe -- AUTO
//   recipeName: "", //the name of the recipe -- USER
//   recipeMethod: -1, //Crockpot, grilled, oven, stovetop  --USER
//   mainRecipeBase: -1, // chicken, beef, pasta, other--USER
//   recipeDuration: "", // from start to finish, how long does it take to cook  --USER
//   recipeSteps: [], // step by stem instructions to make meal - list of strings -- USER
//   recipeIngredients: [],
//   glutenFree: false,
// };

const ingredientDetail = {
  name: "",
  amount: "",
  fraction: "",
  unit: "",
};

const EditRecipe = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useContext(Context);
  const [editRecipe, setRecipe] = useState(
    state.recipeList.find((recipe) => recipe.id === id)
  );
  const [step, setStep] = useState("");
  const [ingredient, setIngredient] = useState(ingredientDetail);

  const cookingMethod = [
    "CrockPot",
    "Stove Top",
    "Grill",
    "Smoker",
    "Oven",
    "No Bake",
    "Other",
  ];

  const recipeBase = [
    "Chicken",
    "Beef",
    "Pork",
    "Veggie",
    "Pasta",
    "Rice",
    "Other",
  ];

  const recipeDuration = [
    "< 30 Minutes",
    "30 - 60 Minutes",
    "1 - 2 Hours",
    "2 - 4 Hours",
    "4+ Hours",
  ];

  const fractions = ["1/16", "1/8", "1/4", "1/3", "1/2", "2/3", "3/4", "7/8"];

  const units = ["cups", "tbls", "tsp", "cans", "lbs", "oz"];

  const onChange = (e) => {
    setRecipe({ ...editRecipe, [e.target.name]: e.target.value });
  };

  const stepChange = (e) => {
    setStep(e.target.value);
  };

  const addStep = (e) => {
    e.preventDefault();
    let newStep = [step];
    let newRecipe = editRecipe.recipeSteps.concat(newStep);
    setRecipe({ ...editRecipe, recipeSteps: newRecipe });
    setStep("");
  };

  const ingredientChange = (e) => {
    setIngredient({ ...ingredient, [e.target.name]: e.target.value });
  };

  const addIngredient = (e) => {
    e.preventDefault();
    if (ingredient.fraction === "") {
      let newIng = {
        name: ingredient.name,
        amount: ingredient.amount,
        unit: ingredient.unit,
      };
      let newIngList = editRecipe.recipeIngredients.concat(newIng);
      setRecipe({ ...editRecipe, recipeIngredients: newIngList });
      setIngredient(ingredientDetail);
    } else {
      let newIng = {
        name: ingredient.name,
        amount: `${ingredient.amount} ${ingredient.fraction}`,
        unit: ingredient.unit,
      };
      let newFraIngList = editRecipe.recipeIngredients.concat(newIng);
      setRecipe({ ...editRecipe, recipeIngredients: newFraIngList });
      setIngredient(ingredientDetail);
    }
  };

  const glutenCheck = () => {
    setRecipe({ ...editRecipe, glutenFree: !editRecipe.glutenFree });
  };

  const saveRecipe = () => {
    let recipeListCopy = state.recipeList;
    let updatedList = recipeListCopy.map((recipe) => {
        if(recipe.id === id){
            return editRecipe
        }
        return recipe
    })
    dispatch({type: 'updateRecipeList', payload: updatedList})
    navigate(`/myrecipes/${editRecipe.id}`)
  }



  return (
    <div id="editRecipeForm">
        {console.log('recipe: ', editRecipe)}
      <Form>
        <Form.Group className="mb-3" controlId="formRecipeName">
          <Form.Label>Recipe Name</Form.Label>
          <Form.Control
            type="text"
            value={editRecipe.name}
            onChange={onChange}
            placeholder="Example: Million Dollar Spaghetti"
            name="recipeName"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRecipeCookingMethod">
          <Form.Label>Recipe Cooking Method:</Form.Label>
          <Form.Select
            value={editRecipe.recipeMethod}
            onChange={onChange}
            name="recipeMethod"
          >
            <option></option>
            {cookingMethod.map((type, index) => (
              <option key={uuidv4()} value={type}>
                {type}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRecipeBase">
          <Form.Label>Main Recipe Base:</Form.Label>
          <Form.Select
            value={editRecipe.mainRecipeBase}
            onChange={onChange}
            name="mainRecipeBase"
          >
            <option></option>
            {recipeBase.map((type, index) => (
              <option key={uuidv4()} value={type}>
                {type}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRecipeDuration">
          <Form.Label>Recipe Duration:</Form.Label>
          <Form.Select
            value={editRecipe.recipeDuration}
            onChange={onChange}
            name="recipeDuration"
          >
            <option></option>
            {recipeDuration.map((type, index) => (
              <option key={uuidv4()} value={type}>
                {type}
              </option>
            ))}
          </Form.Select>
          <Form.Text className="text-muted">
            From start to finish, how long will it take to make this recipe?
          </Form.Text>
        </Form.Group>
        {/* <Form> */}
        <Form.Group className="mb-3" controlId="formAddStep">
          <Form.Label>Recipe Step:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Example: Mix all dry ingredients"
            value={step}
            onChange={stepChange}
          />
          <Button variant="primary" id="addStepButton" onClick={addStep}>
            Add Step
          </Button>
          <h4>Steps:</h4>
          <ListGroup id="stepsList">
            {editRecipe.recipeSteps.map((step, index) => (
              <ListGroup.Item
                variant="success"
                className="steps-listgroup-item"
                key={uuidv4()}
              >
                Step {index + 1} - {step}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Form.Group>
        {/* </Form> */}

        <h3>Ingredients</h3>
        {/* <Form> */}
        <Container>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ingredient:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Example: Baked Beans"
                  value={ingredient.name}
                  onChange={ingredientChange}
                  name="name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Amount:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Whole number"
                  value={ingredient.amount}
                  onChange={ingredientChange}
                  name="amount"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Fraction:</Form.Label>
                <Form.Select
                  value={ingredient.fraction}
                  onChange={ingredientChange}
                  name="fraction"
                >
                  <option></option>
                  {fractions.map((type, index) => (
                    <option key={uuidv4()} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Unit:</Form.Label>
                <Form.Select
                  value={ingredient.unit}
                  onChange={ingredientChange}
                  name="unit"
                >
                  <option></option>
                  {units.map((type, index) => (
                    <option key={uuidv4()} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="primary"
            id="addIngredientButton"
            onClick={addIngredient}
          >
            Add Incredient
          </Button>
          <h4>Ingredients:</h4>
          <ListGroup id="ingredientList">
            {editRecipe.recipeIngredients.map((ing) => (
              <ListGroup.Item
                className="ingredient-listgroup-item"
                key={uuidv4()}
              >
                {ing.amount} {ing.unit} - {ing.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>

        {/* </Form> */}

        <Form.Group className="mb-3" controlId="isGluetenFree">
          {editRecipe.glutenFree ? (
            <Form.Check
              type="checkbox"
              label="Glueten Free?"
              onChange={glutenCheck}
              checked
            />
          ) : (
            <Form.Check
              type="checkbox"
              label="Glueten Free?"
              onChange={glutenCheck}
            />
          )}
        </Form.Group>
        <Button variant="primary" onClick={saveRecipe}>
          Save Recipe
        </Button>
      </Form>
    </div>
  );
};

export default EditRecipe;
