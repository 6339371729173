import React from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const RecipeSearchBar = ({ searchQuery, setSearchQuery }) => {
  const navigate = useNavigate();
  function onSubmit(e) {
    e.preventDefault();
    navigate(`?s=${searchQuery}`);
  }

  return (
    <Form action="/" method="get" onSubmit={onSubmit}>
        <Form.Control
          value={searchQuery}
          onInput={(e) => setSearchQuery(e.target.value)}
          type="text"
          id="item-header-search"
          placeholder="Search items"
          name="s"
        />
    </Form>
  );
};

export default RecipeSearchBar;
