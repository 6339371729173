import React from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const RecipeFilter = ({onChange, setFilter, filter, seederData}) => {


    return (
        <Row>
            <Col>
                <Form id="filter-form">
                    <Form.Group className="mb-3" controlId="formRecipeCookingMethod">
                        <Form.Label>Recipe Cooking Method:</Form.Label>
                        <Form.Select onChange={onChange} name="method" value={filter.method}>
                            <option></option>
                            {seederData.recipeMethods !== undefined ? seederData.recipeMethods.map((type, index) => (
                                <option key={uuidv4()} value={type.name}>
                                    {type.name}
                                </option>
                            )) : "Options Loading" }
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formRecipeBase">
                        <Form.Label>Main Recipe Base:</Form.Label>
                        <Form.Select value={filter.base} onChange={onChange} name="base">
                            <option></option>
                            {seederData.recipeBases !== undefined ? seederData.recipeBases.map((type, index) => (
                                <option key={uuidv4()} value={type.name}>
                                    {type.name}
                                </option>
                            )) : "Options Loading"}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formRecipeDuration">
                        <Form.Label>Recipe Duration:</Form.Label>
                        <Form.Select
                            value={filter.duration}
                            onChange={onChange}
                            name="duration"
                        >
                            <option></option>
                            {seederData.recipeDurations !== undefined ? seederData.recipeDurations.map((type, index) => (
                                <option key={uuidv4()} value={type.name}>
                                    {type.name}
                                </option>
                            )) : "Options Loading"}
                        </Form.Select>
                        <Form.Text className="text-muted">
                            From start to finish, how long will it take to make this recipe?
                        </Form.Text>
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    );
}

export default RecipeFilter;
