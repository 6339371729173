import React, { useEffect, useReducer } from 'react';
import { Outlet } from 'react-router-dom';
import './css/App.css';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Context from './Context';
import { v4 as uuidv4 } from 'uuid';
import recipeslist from './recipes.json'
import shoppingList from './SourceData/shoppingList.json'

const ingredient = {
  type: '', //veg, meat, grain, pasta, bread
  name: '', //name of ingredient
  amount: 0, // refers to the number of the amount
  measurement: '', // the measurement type aka cups, teaspoons, tablespoons, oz, cans
}

const pantry = {
  name: '',
  amount: 0,
  measurement: ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateRecipeList':
      let newData = [...action.payload]
      return { ...state, recipeList: newData }
    case 'updatePantry':
      return { ...state, pantry: action.payload }
    case 'addToShoppingList':
      return { ...state, shoppingList: action.payload }
    case 'updateSeederData':
      return { ...state, seederData: action.payload }
    default:
      break;
  }
}

const initialState = {
  // recipeList: JSON.parse(JSON.stringify(recipeslist)),
  recipeList: [],
  editMode: false,
  addMode: false,
  // shoppingList: JSON.parse(JSON.stringify(shoppingList)),
  shoppingList: [],
  pantry: [],
  newRecipe: {},
  apiUrl: "https://lty-backend.herokuapp.com",
  // apiUrl: "http://localhost:8080",
  seederData: {}
}

function App() {

  const [state, dispatch] = useReducer(reducer, initialState)

  //load seeder data and all Recipes
  useEffect(() => {
    getSeederData();
    getAllRecipes();
  }, [])

  const getAllRecipes = () => {
    fetch(`${state.apiUrl}/api/recipes`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let recipes = data.recipeList;
        dispatch({ type: 'updateRecipeList', payload: recipes })
      })
  }

  const getSeederData = () => {
    fetch(`${state.apiUrl}/api/seeder`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let seederData = data;
        dispatch({ type: 'updateSeederData', payload: seederData })
      })
  }

  return (
    <Context.Provider value={[state, dispatch]}>
      <Header />
      <Outlet />
    </Context.Provider>
  );
}

export default App;
