// import { Button } from "bootstrap";
import React, { useContext } from "react";
import { ListGroup, Button } from "react-bootstrap";
import Context from "../../Context";
import '../../css/ShoppingList.css';

function ShoppingListItem({ item, index }) {
  const [state, dispatch] = useContext(Context);

  function removeItemHandler(i) {
    let copyList = [...state.shoppingList];
    copyList.splice(i, 1);
    dispatch({ type: "addToShoppingList", payload: copyList });
  }

  function editText(i) {
    let copyList = [...state.shoppingList];
    const newText = prompt(
      "What edits do you want to make to the current ToDo Item?",
      item
    );
    copyList.splice(i, 1);
    copyList.splice(i, 0, newText);
    dispatch({ type: "addToShoppingList", payload: copyList });
  }

  return (
    <ListGroup.Item className="shopping-list-item">
      <div>
        <Button
          className="shopping-list-item-button"
          value={item}
          onClick={() => {
            removeItemHandler(index);
          }}
        >
          Delete
        </Button>
        <Button
          className="shopping-list-item-button"
          onClick={() => {
            editText(index);
          }}
        >
          Edit
        </Button>
        <span className="liItem"> {item}</span>
      </div>
    </ListGroup.Item>
  );
}

export default ShoppingListItem;
