import React, { useContext, useRef } from "react";
import Context from "../Context";
import { Col, Container, ListGroup, Row, Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ShoppingListItem from "./Partials/ShoppingListItem";
import '../css/ShoppingList.css'
import { useReactToPrint } from "react-to-print";


const Mylist = () => {
  const [state, dispatch] = useContext(Context);


  const shoppingListComponentRef = useRef();
  const printMealList = useReactToPrint({
      content: () => shoppingListComponentRef.current,

  });

  return (
    <Container id="shoppinglistgroup">
      <Row>
        <Col >
          <ListGroup variant="flush" ref={shoppingListComponentRef}>
            <h2>Shopping List</h2>
            {/* {state.shoppingList.map((item) => <ListGroup.Item key={uuidv4()} >{item} <Button>Delete</Button></ListGroup.Item>)} */}
            {state.shoppingList.map((item, index) => (
              <ShoppingListItem item={item} index={index} key={uuidv4()} />
            ))}
          </ListGroup>
          {state.shoppingList.length !== 0 ? <Button onClick={printMealList}>Print List</Button> : ""}
        </Col>
      </Row>
    </Container>
  );
};

export default Mylist;
